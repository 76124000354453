<template>
  <div class="animated fadeIn leave-list">
    <loading v-if="vLoading" />
    <v-col cols="12">
      <v-card-title style="background-color: transparent !important">
        <v-row>
          <v-col>
            <h3>کلیه بازخورد‌ها</h3>
          </v-col>
          <v-col> </v-col>
        </v-row>
      </v-card-title>
      <v-card class="cards pa-3">
        <v-card-text>
          <div>
            <v-text-field
              v-model="Filter"
              prepend-inner-icon="mdi-magnify"
              label="جستجو"
              single-line
              hide-details
              filled
              rounded
              clearable
              class="text-right w-80 mt-2 mb-2 search-input"
            ></v-text-field>

            <b-table
              responsive
              show-empty
              :fields="Fields"
              :items="Items"
              empty-text="بازخوردی برای نمایش وجود ندارد"
              empty-filtered-text="بازخوردی برای نمایش وجود ندارد"
              :busy="Busy"
              :filter="Filter"
              :current-page="CurrentPage"
              :per-page="PerPage"
              @filtered="onFiltered"
              tbody-tr-class="hover-tr"
            >
              <template v-slot:head()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.label }}
                </div>
              </template>
              <template v-slot:cell()="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.value }}
                </div>
              </template>

              <template v-slot:cell(description)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    data.value.length > 20
                      ? data.value.slice(0, 20) + "..."
                      : data.value
                  }}
                </div>
              </template>
              <template v-slot:cell(note)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{
                    data.value
                      ? data.value.length > 20
                        ? data.value.slice(0, 20) + "..."
                        : data.value
                      : "-"
                  }}
                </div>
              </template>
              <template v-slot:cell(operation)="data">
                <v-btn
                  class="mr-2 py-2 primary-btn"
                  style="width: 150px"
                  @click="feedbackDetails(data.item)"
                >
                  <v-icon class="pe-2">mdi-eye-outline</v-icon>
                  نمایش </v-btn
                >&nbsp;
                <!-- NOTE (b-expire) to expiration of the refree -->
              </template>

              <template v-slot:cell(index)="data">
                <div style="text-align: center; vertical-align: middle">
                  {{ data.index + PerPage * (CurrentPage - 1) + 1 }}
                </div>
              </template>

              <div slot="table-busy" class="text-center primary--text my-2">
                <v-progress-circular
                  indeterminate
                  color="primary"
                  class="align-middle"
                ></v-progress-circular>
              </div>
            </b-table>
            <v-pagination
              v-model="CurrentPage"
              :length="Math.ceil(TotalRows / PerPage)"
              :total-visible="5"
              prev-icon="arrow_back"
              next-icon="arrow_forward"
              style="float: center"
            ></v-pagination>
            <v-select
              label="تعداد در هر صفحه:"
              style="width: 150px"
              v-model="PerPage"
              :items="perPageOptions"
              item-text="text"
              item-value="value"
            ></v-select>
          </div>
        </v-card-text>
      </v-card>
    </v-col>
    <v-dialog v-model="addNoteDialog" width="600">
      <v-card class="pa-3" style="overflow-x: hidden; border-radius: 20px">
        <v-card-title class="mb-2">
          <h4>
            <v-icon>mdi-comment-quote-outline</v-icon>
            ثبت یادداشت
          </h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 close-icon"
            @click="addNoteDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text class="pb-0">
          <v-textarea
            label="یادداشت"
            outlined
            dense
            v-model="selectedNote"
            color="primary"
            rows="10"
            shaped
          >
          </v-textarea>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>

          <v-btn class="primary-btn float-end" outlined @click="submitNote()">
            <v-icon class="pe-2">mdi-note-check-outline</v-icon>
            ثبت
          </v-btn>
          <v-btn class="red-btn" outlined @click="addNoteDialog = false">
            لغو
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="feedbackItemDialog" width="600">
      <v-card class="pa-3" style="border-radius: 20px">
        <v-card-title>
          <h4>جزییات بازخورد</h4>
          <v-spacer></v-spacer>
          <v-icon
            color="red"
            class="mb-2 ms-2 close-icon"
            @click="feedbackItemDialog = false"
            >mdi-close</v-icon
          >
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col class="text-right" cols="12">
              <span class="common-text primary--text"> عنوان:</span>
              <span class="common-text"> {{ feedbackInfo.title }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="common-text primary--text"> نام کاربر:</span>
              <span class="common-text"> {{ feedbackInfo.userName }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="common-text primary--text">وضعیت:</span>
              <span class="common-text"> {{ feedbackInfo.status }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="common-text primary--text">زمان ثبت</span>
              <span class="common-text"> {{ feedbackInfo.submitDate }}</span>
            </v-col>
            <v-col class="text-right" cols="12" sm="6" md="6" lg="6" xl="6">
              <span class="common-text primary--text">زمان بروزرسانی :</span>
              <span class="common-text"> {{ feedbackInfo.updateDate }}</span>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="common-text primary--text">فایل:</span>
              <a
                v-if="feedbackInfo.url"
                :href="feedbackInfo.url"
                class="common-text blue--text ms-1"
                target="_blank"
                >{{ feedbackInfo.fileName }}</a
              >
              <span v-else class="common-text ms-1"
                >فایلی برای این بازخورد بارگذاری نشده است.</span
              >
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <span class="common-text primary--text">توضیحات :</span>
              <p
                class="common-text mt-1 text-justify"
                style="line-height: 24px"
              >
                {{ feedbackInfo.description }}
              </p>
            </v-col>
          </v-row>
          <v-row>
            <v-col class="pt-0">
              <span class="common-text primary--text">یادداشت :</span>
              <p
                class="common-text mt-1 text-justify"
                style="line-height: 24px"
              >
                {{ feedbackInfo.note ? feedbackInfo.note : "-" }}
              </p>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <div class="w-100">
            <v-btn
              class="purple-btn mx-2 py-2"
              @click="openNoteDialog(feedbackInfo)"
            >
              <v-icon class="pe-2">mdi-note-edit-outline</v-icon>

              <span class="common-text">یادداشت</span>
            </v-btn>
            <v-btn
              class="red-btn py-2"
              v-if="feedbackInfo.status == 'باز'"
              @click="openStatusDialog(feedbackInfo)"
            >
              <v-icon class="pe-2">mdi-close-circle-outline</v-icon>
              <span class="common-text">بستن</span>
            </v-btn>
            <v-btn
              class="green-btn py-2"
              @click="openStatusDialog(feedbackInfo)"
              v-else-if="feedbackInfo.status == 'بسته'"
            >
              <v-icon class="pe-2">mdi-check-circle-outline</v-icon>
              <span class="common-text">باز کردن</span>
            </v-btn>
          </div>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="statusDialog" max-width="600px">
      <v-card class="pa-5" style="border-radius: 20px">
        <v-card-title class="text-right">
          <h2 class="common-text">
            آیا از تغییر وضعیت بازخورد "{{ selectedFeedback.title }}" مطمئن
            هستید ؟
          </h2>
        </v-card-title>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            class="primary-btn"
            outlined
            @click="changeStatus(selectedFeedback)"
          >
            <span class="common-text">تایید</span>
          </v-btn>
          <v-btn class="red-btn" outlined @click="statusDialog = false">
            <span class="common-text">لغو</span>
          </v-btn>
          <v-spacer></v-spacer>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </div>
</template>
<script>
import moment from "moment-jalaali";
import VuePersianDatetimePicker from "vue-persian-datetime-picker";

export default {
  components: {
    datePicker: VuePersianDatetimePicker,
  },
  data() {
    return {
      deviceType: "",
      CurrentPage: 1,
      PerPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
        { value: 100, text: "100" },
      ],
      TotalRows: "",
      Filter: "",
      Busy: true,
      Fields: [
        { key: "index", label: "#" },
        { key: "title", label: "عنوان" },
        { key: "userName", label: "نام کاربر" },
        { key: "submitDate", label: "زمان ثبت" },
        { key: "updateDate", label: "زمان بروزرسانی" },
        { key: "status", label: "وضعیت" },
        { key: "description", label: "توضیحات" },
        { key: "note", label: "یادداشت" },
        { key: "operation", label: "توضیحات" },
      ],
      Items: [],
      dateFrom: moment(new Date()).format("jYYYY/jMM/jDD"),
      dateTo: moment(new Date()).format("jYYYY/jMM/jDD"),
      timeFrom: "00:00",
      timeTo: "23:59",
      currentDate: moment(new Date()).format("jYYYY-jMM-jDD"),
      addNoteDialog: false,
      feedbackItemDialog: false,
      selectedEdit: {},
      feedbackInfo: {},
      statusDialog: false,
      selectedFeedback: {},
      selectedNote: "",
    };
  },
  methods: {
    getFeedbacks() {
      this.Busy = true;
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/feedbacks/getAll",
          {},
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          this.Busy = false;
          this.vLoading = false;
          if (res.status == 200) {
            this.Items = res.data;
            this.Items = res.data.feedbacks;
            this.TotalRows = this.Items.length;
            this.CurrentPage = 1;
            if (res.data.unSeenfeedbacks) {
              localStorage.setItem("unSeenfeedbacks", res.data.unSeenfeedbacks); //get from back
              // dispatch a CustomEvent when add feedbacks to storage:
              window.dispatchEvent(
                new CustomEvent("feedbacks-changed", {
                  detail: {
                    storage: localStorage.getItem("unSeenfeedbacks"),
                  },
                })
              );
            }
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.vLoading = false;
          this.Busy = false;
        });
    },
    submitNote() {
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/feedback/note",
          {
            feedbackId: this.selectedId,
            note: this.selectedNote,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.addNoteDialog = false;
            if (this.feedbackItemDialog == true) {
              this.feedbackItemDialog = false;
            }
            this.getFeedbacks();
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
            this.addNoteDialog = false;
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
          this.addNoteDialog = false;
        });
    },
    openStatusDialog(item) {
      this.selectedFeedback = item;
      this.statusDialog = true;
    },
    changeStatus(item) {
      let feedbackStatus;
      if (item.status == "باز") {
        feedbackStatus = "close";
      } else {
        feedbackStatus = "open";
      }
      this.$http
        .post(
          this.baseUrl + "/clinic/admin/feedback/" + feedbackStatus,
          {
            feedbackId: item.id,
          },
          {
            headers: {
              Authorization: "Bearer: " + localStorage.getItem("token"),
            },
          }
        )
        .then((res) => {
          if (res.status == 200) {
            this.toast(res.data, "success");
            this.statusDialog = false;
            if (this.feedbackItemDialog == true) {
              this.feedbackItemDialog = false;
            }
            this.getFeedbacks();
            this.CurrentPage = 1;
          } else {
            this.toast("خطا: مشکلی پیش آمده. مجددا امتحان کنید.", "error");
          }
        })
        .catch((err) => {
          this.toast("خطا: " + err.response.data, "error");
        });
    },
    feedbackDetails(item) {
      this.feedbackInfo = JSON.parse(JSON.stringify(item));
      this.feedbackItemDialog = true;
    },
    openNoteDialog(item) {
      this.selectedNote = item.note;
      this.selectedId = item.id;
      this.addNoteDialog = true;
    },
    onFiltered(filteredItems) {
      // Trigger pagination to update the number of buttons/pages due to filtering
      this.TotalRows = filteredItems.length;
      this.CurrentPage = 1;
    },
  },
  mounted() {
    if (window.screen.width < 950) {
      this.deviceType = "mobile";
    } else {
      if (document.body.clientWidth < 950) {
        this.deviceType = "mobile";
      } else {
        this.deviceType = "desktop";
      }
    }
    this.role = localStorage.getItem("role");
    this.vLoading = true;
    this.getFeedbacks();
  },
};
</script>
<style>
.v-dialog:not(.v-dialog--fullscreen) {
  max-height: 83% !important;
}
</style>
